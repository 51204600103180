// extracted by mini-css-extract-plugin
export var categoriesNavLinkItem = "layout-module--categories-nav-link-item--OhAvX";
export var categoriesNavLinkText = "layout-module--categories-nav-link-text--VMKs-";
export var categoriesNavLinks = "layout-module--categories-nav-links--Ai8jt";
export var container = "layout-module--container--eLBMS";
export var containerBottom = "layout-module--container-bottom--2YRvP";
export var containerHeader = "layout-module--container-header--R7idi";
export var headerNavLinkItem = "layout-module--header-nav-link-item--PbkXx";
export var headerNavLinkText = "layout-module--header-nav-link-text--Bboqx";
export var headerNavLinks = "layout-module--header-nav-links--HrYbn";
export var heading = "layout-module--heading--8VjLO";
export var imageMe = "layout-module--image-me--xFTo+";
export var imageMeLisboa = "layout-module--image-me-lisboa--PkxoI";
export var navLinkItem = "layout-module--nav-link-item--pfCo2";
export var navLinkText = "layout-module--nav-link-text--ac2nV";
export var navLinks = "layout-module--nav-links--EROwB";
export var pageNavigation = "layout-module--page-navigation--AB3s3";
export var pageNavigationLink = "layout-module--page-navigation-link--MThFI";
export var pageNavigationSpace = "layout-module--page-navigation-space--7gzzb";
export var postCategoriesNavLinkItem = "layout-module--post-categories-nav-link-item--lCaIm";
export var postCategoriesNavLinkText = "layout-module--post-categories-nav-link-text--VNRCq";
export var postCategoriesNavLinks = "layout-module--post-categories-nav-links--D0yZj";
export var postTagsNavLinkItem = "layout-module--post-tags-nav-link-item--89xxc";
export var postTagsNavLinkText = "layout-module--post-tags-nav-link-text--STohM";
export var postTagsNavLinks = "layout-module--post-tags-nav-links--bFzRl";
export var tagsNavLinkItem = "layout-module--tags-nav-link-item--8TPqr";
export var tagsNavLinkText = "layout-module--tags-nav-link-text--fgjOK";
export var tagsNavLinks = "layout-module--tags-nav-links--KWNU8";
export var tempCategoriesNavLinkItem = "layout-module--temp-categories-nav-link-item--uhnra";
export var tempCategoriesNavLinkText = "layout-module--temp-categories-nav-link-text--AManx";
export var tempCategoriesNavLinks = "layout-module--temp-categories-nav-links---SH8K";
export var tempTagsNavLinkItem = "layout-module--temp-tags-nav-link-item--FZbpi";
export var tempTagsNavLinkText = "layout-module--temp-tags-nav-link-text--jSCO6";
export var tempTagsNavLinks = "layout-module--temp-tags-nav-links--MX0fX";