import React from "react"


import {
  
  containerBottom
} from '../components/layout.module.css'

export default function Bottom() {
  return (
       
      <div className={containerBottom}>
            
	   A Blog made with Gatsby React and GraphQL	   

      </div>
   
  );
}


